.upload-info {
  font-size: 0.8em;
  margin-top: 15px;
}

.upload-info ul {
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border: 0;
  width: 750px;
}

.upload-info li {
  padding: 0;
  margin-right: 5px;
  margin-bottom: 0;
  float: left;
  position: relative;
  display: block;
}

.upload-info li a {
  padding: 5px 10px;
  margin: 0;
  color: #000;
  background-color: #eaeaea;
  border: 0;
  position: relative;
  display: block;
  text-decoration: none;
}

.upload-info li.active a {
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
  background-color: #002440;
}

.upload-info .content {
  display: table;
  height: 1px;
}

.upload-info .content .script {
  display: table-cell;
  width: 100%;
  max-width: 1px;
  padding: 0 10px 8px 10px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.5;
  color: #fff;
  background-color: #002440;
  border-color: #002440;
  border-style: solid;
  border-width: 1px 0 1px 1px;
}

.upload-info .content .copy-button {
  display: table-cell;
}

.upload-info .content .copy-button button {
  height: 100%;
  min-height: 42px;
  vertical-align: baseline;
  border-radius: 0px;
}

.install-script:before {
  content: "> ";
}

.install-script {
  margin: 10px 0 10px;
  color: #fff;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  background-color: #002440;
  border-color: #002440;
  border-style: solid;
  border-width: 1px;
}
